<template>
  <div class="sidebar-contact" :class="{ active: isSidebarActive }">
    <div :class="{ bounce: isHovered }"
      @mouseover="isHovered = true"
      @mouseout="isHovered = false"
      class="toggle" @click="toggleSidebar"></div>
    <h2>Nous Contacter</h2>
    <div class="scroll">
      <form @submit.prevent="submitForm">
        <input
          type="text"
          v-model="name"
          name="your-name"
          placeholder="Ton prénom"
        />
        <div class="error-message">{{ nameError }}</div>
        <input
          type="email"
          v-model="email"
          name="your-email"
          placeholder="Ton Email"
        />
        <div class="error-message">{{ emailError }}</div>
        <textarea
          v-model="message"
          name="your-message"
          placeholder="Ecris ton message ici..."
        ></textarea>
        <div class="error-message">{{ messageError }}</div>
        <input type="submit" value="Envoyer" />
      </form>

      <div class="overlay" v-if="showSuccess">
        <div class="overlay-content">
          <div class="check-icon">&#10003;</div>
          <div class="success-message">Ton message a bien été envoyé.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import "animate.css/animate.min.css";

export default {
  data() {
    return {
      isSidebarActive: false,
      name: "",
      email: "",
      message: "",
      showSuccess: false,
      nameError: "",
      emailError: "",
      messageError: "",
      isHovered: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isSidebarActive = !this.isSidebarActive;
    },
    async submitForm() {
      this.nameError = "";
      this.emailError = "";
      this.messageError = "";

      if (!this.name) {
        this.nameError = "Veuillez saisir votre nom";
      }
      if (!this.email) {
        this.emailError = "Veuillez saisir votre email";
      }
      if (!this.message) {
        this.messageError = "Veuillez saisir votre message";
      }

      if (!this.name || !this.email || !this.message) {
        return;
      }

      try {
        const response = await axios.post(
          "https://admin.londonstreetinstitute.fr/wp-json/contact-form-7/v1/contact-forms/21/feedback",
          {
            "your-name": this.name,
            "your-email": this.email,
            "your-message": this.message,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        this.resetForm();

        this.showSuccess = true;

        setTimeout(() => {
          this.isSidebarActive = false;
          this.showSuccess = false;
        }, 5000);

        // Handle the response as needed
        console.log(response.data);
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    },
    resetForm() {
      this.name = "";
      this.email = "";
      this.message = "";
    },
  },
};
</script>

<style scoped>
.bounce {
  animation: bounce 0.5s;
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.error-message {
  color: red;
  margin-bottom: 10px;
  font-size: 12px;
  text-align: left;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
}

.check-icon {
  font-size: 48px;
  color: green;
}

.success-message {
  margin-top: 10px;
}

.sidebar-contact {
  position: fixed;
  top: 50%;
  left: -350px;
  transform: translateY(-50%);
  width: 350px;
  height: auto;
  padding: 40px;
  background: #fff;
  box-shadow: 0 20px 50px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  transition: 0.5s;
}
.sidebar-contact.active {
  left: 0;
}
.sidebar-contact input,
.sidebar-contact textarea {
  width: 100%;
  height: 36px;
  padding: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.5);
  outline: none;
}
.sidebar-contact h2 {
  margin: 0 0 20px;
  padding: 0;
}
.sidebar-contact textarea {
  height: 60px;
  resize: none;
}
.sidebar-contact input[type="submit"] {
  background: #00bcd4;
  color: #fffdd8;
  cursor: pointer;
  border: none;
  font-size: 18px;
}
.toggle {
  position: absolute;
  height: 48px;
  width: 48px;
  text-align: center;
  cursor: pointer;
  /* background: #f00; */
  top: 0;
  right: -48px;
  line-height: 48px;
  border: solid 2px #fffdd8;
  border-left: none;
  border-radius: 1px 25px 25px 1px;
}
.toggle:before {
  content: "\f003";
  font-family: fontAwesome;
  font-size: 18px;
  color: #fff;
}
.toggle.active:before {
  content: "\f00d";
}
@media (max-width: 768px) {
  /* .sidebar-contact {
    width: 100%;
    height: 100%;
    left: -100%;
  } */
  .sidebar-contact .toggle {
    top: 50%;
    transition: 0.5s;
  }
  .sidebar-contact.active .toggle {
    top: 0;
    transform: translateY(0);
  }
  .scroll {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
}
</style>
