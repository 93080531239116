<template>
  <div class="container">
    <div ref="engagements" class="section" @scroll="handleScroll">
      <div :class="['step-wrapper', { 'move-line': isMoving }]">
        <template v-for="(item, index) in engagementsData" :key="index">
          <article
            :class="['linestep', 'linestep' + (index + 1)]"
            :style="getArticleStyle(index)"
          >
            <span class="num">{{ index + 1 }}</span>
            <p :class="getTextPosition(index)">{{ item.text }}</p>
            <div v-if="item.position === 'left'" class="triangle-left"></div>
            <div v-if="item.position === 'right'" class="triangle-right"></div>
          </article>
        </template>
        <!-- Rest of the code -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMoving: false,
      engagementsData: [
        {
          text: "Évaluation en ligne du niveau écrit et oral selon le CECRL",
          position: "left",
        },
        {
          text: "Acces à la plateforme E-learning 24h/24 et 7j/7",
          position: "right",
        },
        {
          text: "L'apprenant recevra sa note et sera dirigé vers la formation la plus adéquate pour lui",
          position: "left",
        },
        {
          text: "Détermination des objectifs linguistiques en lien avec le formateur et coordinateur",
          position: "right",
        },
        {
          text: "Préparation des cours et jeux de rôles suivant la méthode London Street Insitute",
          position: "left",
        },
        {
          text: "Un formateur-coach individuel identique pendant toute la formation",
          position: "right",
        },
        {
          text: "Test de milieu de session et évaluation de fin de forfait avec possibilité de passer son TOEIC",
          position: "left",
        },
        {
          text: "Grille de progression personnalisée, temps passé en formation automatisé",
          position: "right",
        },
        {
          text: "Suivi post-cours par le formateur pour l'apprenant, incluant les points clés abordés (expressions, vocabulaire, applications grammaticales, etc.)",
          position: "left",
        },
        {
          text: "Formateurs anglais/américains natifs (au choix) polyvalents, spécialisés ou experts dans diverses fonctions et métiers",
          position: "right",
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const engagementsSection = this.$refs.engagements;
      const sectionTop = engagementsSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (sectionTop < windowHeight) {
        this.startMovement();
      }
    },
    startMovement() {
      this.isMoving = true;
    },
getArticleStyle(index) {
  const topPosition = index * 100;
  if (index > 0) {
    return {
      left: "50%",
      top: `${topPosition}px`,
      transform: "translateX(-50%) translateY(-50%)",
    };
  }
  return {
    left: "50%",
    top: "0",
    transform: "translateX(-50%)",
  };
},

    getTextPosition(index) {
      return this.engagementsData[index].position;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1260px;
  margin: 0 auto;
  position: relative;
}

.section {
  height: 100vh;
  scroll-snap-type: y mandatory;
}

.step-wrapper {
  margin: 30px;
  margin-bottom: 200px;
  position: relative;
}

.linestep {
  text-align: center;
  max-width: 250px;
  position: absolute;
}

.linestep .num {
  color: #222;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-weight: bold;
  background: #fffdd8;
}

.linestep p {
  margin: 0;
  padding: 0 10px;
  background-color: rgba(255,253,216, 0.12);
  border-radius: 50px;
  padding: 15px 25px;
  width: 300px!important;
  margin-left: 15px;
  margin-right: 15px;
}

.linestep.bottom {
  transform: translateX(-50%);
}


.triangle-left {
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
  border-top: 5px solid transparent;
  border-right: 10px solid #fffdd8;
  border-bottom: 5px solid transparent;
}

.triangle-right {
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  border-top: 5px solid transparent;
  border-left: 10px solid #fffdd8;
  border-bottom: 5px solid transparent;
}


@keyframes show-step-item {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes show-step-item-left {
  from {
    transform: translateX(-250px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes show-step-item-right {
  from {
    transform: translateX(250px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes moveline {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
