import { createRouter, createWebHistory } from 'vue-router';
import Pedagogie from '@/components/Pedagogie.vue';
import Main from '@/components/Main.vue';
import FormationCpf from '@/components/FormationCpf.vue';

const routes = [
  {
    path: "/",
    name: "Home",
    component: Main,
    meta : { title: 'Centre de formation à distance en langues de qualité' },
  },
  {
    path: "/pedagogie",
    name: "Pedagogie",
    component: Pedagogie,
    meta : { title: 'Pédagogie' },
  },
  {
    path: "/formations",
    name: "FormationCpf",
    component: FormationCpf,
    meta : { title: 'Nos Formations en langue à distance' },
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  const baseTitle = 'London Street Institute';
  document.title = to.meta.title ? `${baseTitle} | ${to.meta.title}` : baseTitle;
});

export default router;