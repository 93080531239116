<template>
  <div>
    <div class="laptop-container">
      <div class="laptop">
        <svg
          viewBox="0 0 480 268"
          width="100%"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <g class="laptop-bottom">
            <path
              transform="translate(0, 250)"
              d="M450,18 L31,18 C21,18 34e-13,14 0,12 L 480,12 C480,14 460,18 450,18 Z"
            />
            <rect x="0" y="250" width="480" height="12" />
          </g>
          <path
            class="laptop-lid"
            d="M 70,240 l 341.6 0 l 52.17 0 l -434.7 0 l 52 0 z M 426,250 l -368.4 0 l -56.4 0 c 0 -8.46 8 -8.46 18.05 -8.46 l 443.6 0 c 19.7 0 18 8 18 8.5 l -56 0 z"
          />
          <rect class="screen-bg" x="70" y="24" width="342" height="216" />
          <g class="grid">
            <polygon points="94,51 388,51"></polygon>
            <polygon points="94,83 388,83"></polygon>
            <polygon points="94,115 388,115"></polygon>
            <polygon points="94,147 388,147"></polygon>
            <polygon points="94,179 388,179"></polygon>
            <polygon points="94,211 388,211"></polygon>
          </g>
          <g transform="translate(96, 58)">
            <polyline
              class="chart"
              points="0 122 15 107 30 110 46 97 61 94 76 85 92 95 107 113 122 72 137 73 153 53 168 55 183 49 199 30 214 0 229 10 244 29 260 25 275 21 290 4"
            ></polyline>
            <polyline
              class="chart"
              stroke-dasharray="395"
              stroke-dashoffset="395"
              points="0 140 15 144 30 142 46 131 61 119 76 120 92 120.82458 107 125 122 113 137 115 153 125 168 112 183 78 199 52 214 96 229 83 244 90 260 104 275 99 290 87"
            ></polyline>
            <polyline
              class="chart"
              stroke-dasharray="332"
              stroke-dashoffset="332"
              points="0 122 15 128 30 130 46 115 61 107 76 112 92 98 107 93 122 94 137 92 153 82 168 66 183 67 199 64 214 52 229 52 244 60 260 51 275 36 290 36"
            ></polyline>
            <polyline
              class="chart"
              stroke-dasharray="425"
              stroke-dashoffset="425"
              points="0 152 15 129 30 98 46 66 61 73 76 68 92 79 107 77 122 90 137 102 153 104 168 85 183 83 199 84 214 74 229 57 244 40 260 83 275 77 290 61"
            ></polyline>
          </g>
          <g class="logo" transform="translate(82, 34)">
            <path
              d="M2,0 L30,0 C31,0 32,1 32,2 L32,30 C32,31 31,32 30,32 L2,32 C1,32 0,31 0,30 L0,2 C0,1 1,0 2,0 Z"
            />
            <path
              d="M24,6 L20,6 C20,6 20,6 20,6.5 L20,15 C20,16 19,16 19,16 L19,16 C19,16 18,16 18,15.5 L18,9 C18,9 18,8.5 18,8.5 C18,8.5 18,8.5 18,8.5 L14,8.5 C14,8.5 13,9 13,9 L13,15.5 C13,16 13,16 13,16 L13,16 C12,16 12,16 12,15.5 L12,15 L12,11 C12,11 12,11 12,11 L8,11 C7.5,11 7,11 7,11 L7,17 L24,17 L24,6.5 C24,6 24,6 24,6 Z"
            />
            <text x="4" y="27">wikifolio</text>
          </g>
        </svg>
      </div>
      <div class="ai text-block big">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 50 50"
        >
          <path
            d="M24 32h-2v3h-6v5h-5.085a1.5 1.5 0 1 1 0-1H15v-5h6v-2h-1a2 2 0 0 1-2-2v-2.028h-1.972V32H12.95a2.5 2.5 0 1 1 0-1h2.078v-4.028H18V25H8v-5.085a1.5 1.5 0 1 1 1 0V24h9v-2h-6V9.915a1.5 1.5 0 1 1 1 0V21h5v-1a2 2 0 0 1 2-2h2v-5h-3.085a1.5 1.5 0 1 1 0-1H23v6h2v-7h2V7.95a2.5 2.5 0 1 1 1 0V12h-2v6h2v-4h5v-2.085a1.5 1.5 0 1 1 1 0V15h-5v3h1a2 2 0 0 1 2 2v2h2v-4h6v-2.085a1.5 1.5 0 1 1 1 0V19h-6v4h-3v2h10v3.05a2.5 2.5 0 1 1-1 0V26h-9v2h2v10h7.085a1.5 1.5 0 1 1 0 1H33V29h-1v1a2 2 0 0 1-2 2h-1v10h1.085a1.5 1.5 0 1 1 0 1H28V32h-3v6h-5v5.05a2.5 2.5 0 1 1-1 0V37h5v-5z"
          />
        </svg>
        <div>Artificial Intelligence</div>
      </div>
      <div class="cryptocurrency text-block big">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 50 50"
        >
          <path
            d="M43.041 25.046c0 9.938-8.058 17.996-17.998 17.996-9.936 0-17.994-8.058-17.993-17.996 0-9.938 8.057-17.996 17.994-17.996 9.94 0 17.997 8.058 17.997 17.996zm-10.915-4.409c-.231-2.41-2.312-3.219-4.94-3.449v-3.343H25.15V17.1c-.535 0-1.082.011-1.625.022v-3.277h-2.034l-.001 3.343c-.44.009-.873.017-1.295.017v-.01l-2.808-.001v2.174s1.503-.029 1.478-.001c.825 0 1.093.478 1.171.891v3.81c.057 0 .131.002.216.014h-.216v5.337c-.036.26-.189.673-.765.674.026.023-1.48 0-1.48 0l-.404 2.43h2.649c.493 0 .978.009 1.454.012v3.382h2.034v-3.346c.558.012 1.099.016 1.626.015v3.331h2.035V32.54c3.423-.196 5.818-1.059 6.115-4.272.24-2.587-.977-3.742-2.92-4.208 1.181-.601 1.919-1.66 1.746-3.424zm-2.848 7.231c.001 2.528-4.326 2.24-5.706 2.242v-4.481c1.38 0 5.707-.397 5.706 2.24zm-.946-6.321c0 2.3-3.61 2.03-4.76 2.031v-4.064c1.15 0 4.76-.365 4.76 2.033z"
          />
        </svg>
        <div>Cryptocurrency</div>
      </div>
      <div class="autonomous-driving text-block big">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 50 50"
        >
          <path
            d="M23.11 42.17l-.742-10.388a7.296 7.296 0 0 1-4.15-4.15L7.83 26.89c.874 8.03 7.25 14.406 15.28 15.28zm3.78 0c8.03-.874 14.406-7.25 15.28-15.28l-10.388.742a7.296 7.296 0 0 1-4.15 4.15L26.89 42.17zM7.83 23.11l10.388-.742a7.275 7.275 0 0 1 13.564 0l10.388.742C41.23 14.46 33.9 7.727 25 7.727c-8.9 0-16.229 6.732-17.17 15.383zM25 45C13.954 45 5 36.046 5 25S13.954 5 25 5s20 8.954 20 20-8.954 20-20 20zM20.447.668a.75.75 0 0 1 .274 1.475 23.072 23.072 0 0 0-3.881 1.08.75.75 0 1 1-.527-1.405 24.572 24.572 0 0 1 4.134-1.15zM8.44 3.044a.5.5 0 1 1 .602.797 26.66 26.66 0 0 0-4.883 4.79.5.5 0 0 1-.786-.618 27.66 27.66 0 0 1 5.067-4.97zm5.195-.036a.75.75 0 1 1 .69 1.332 23.357 23.357 0 0 0-9.886 9.797.75.75 0 0 1-1.326-.702A24.857 24.857 0 0 1 13.635 3.008zm15.918 46.324a.75.75 0 0 1-.274-1.475 23.072 23.072 0 0 0 3.881-1.08.75.75 0 1 1 .527 1.405c-1.338.502-2.72.887-4.134 1.15zm12.007-2.376a.5.5 0 1 1-.602-.797 26.66 26.66 0 0 0 4.883-4.79.5.5 0 0 1 .786.618 27.66 27.66 0 0 1-5.067 4.97zm-5.195.036a.75.75 0 0 1-.69-1.332 23.357 23.357 0 0 0 9.886-9.797.75.75 0 0 1 1.326.702 24.857 24.857 0 0 1-10.522 10.427z"
          />
        </svg>
        <div>Autonomous Driving</div>
      </div>
      <div class="commodities text-block big">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 50 50"
        >
          <path
            d="M12 21h-1v-2h1v-8h-2V8h30v3h-2v8h1v2h-1v10h1v2h-1v6h2v3H10v-3h2v-6h-1v-2h1V21zm13 10c2.761 0 5-2.255 5-4.53 0-2.274-2.5-7.823-5-9.47-2.5 1.647-5 7.196-5 9.47 0 2.275 2.239 4.53 5 4.53zm1.222-1.492a.5.5 0 1 1-.444-.896 4.65 4.65 0 0 0 1.986-1.857.5.5 0 0 1 .872.49 5.65 5.65 0 0 1-2.414 2.263z"
          />
        </svg>
        <div>Commodities</div>
      </div>
      <div class="day-trading text-block white-block small">Day Trading</div>
      <div class="robotics text-block white-block medium">Robotics</div>
      <div class="emerging-markets text-block white-block medium">
        Emerging Markets
      </div>
      <div class="e-mobility text-block white-block small">E-Mobility</div>
      <div class="small-caps text-block white-block medium">Small Caps</div>
      <div class="solar text-block white-block medium">Solar</div>
      <div class="german-stocks text-block white-block medium">
        German Stocks
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { gsap, Power1, Power2, Linear } from "gsap";

export default {
  setup() {
    let tl = null;
    const scale = ref(1);

    const mobileScale = () => {
      scale.value = Math.min(window.innerWidth / 577, window.innerHeight / 418);
    };

    const initializeAnimation = () => {
      tl = gsap.timeline({
        onComplete: function () {
          this.restart();
        },
      });

      tl.to(
        ".laptop-lid",
        0.1,
        {
          fill: "#313638",
          stroke: "none",
          ease: Linear.easeNone,
        },
        "+=1"
      );
      tl.to(
        ".laptop-lid",
        0.6,
        {
          attr: {
            d: "M 70,240 l 341.643 0 l 0 -215.73 l -341.643 0 l 0 215.73 z M 426,250 l -368.433 0 l 0 -231.663 c 0 -9.87 8.037 -18.048 18.048 -18.048 l 333.606 0 c 9.87 0 18.048 8.037 18.048 18.048 l 0 231.663 z",
          },
          ease: Power1.easeOut,
        },
        "-=0.1"
      );

      tl.to([".screen-bg", ".grid", ".logo"], 0.1, {
        opacity: 1,
        ease: Power1.easeOut,
      });

      tl.to(".chart", 0.5, {
        strokeDashoffset: 0,
        ease: Power1.easeIn,
      });

      const delta = [
        { x: 12, y: 17 },
        { x: -15, y: 13 },
        { x: -14, y: -13 },
        { x: 21, y: 0 },
        { x: -3, y: 23 },
        { x: -21, y: 11 },
        { x: -21, y: 2 },
        { x: -20, y: 5 },
        { x: 11, y: -27 },
        { x: 24, y: -11 },
        { x: 21, y: 9 },
      ];
      const textBoxes = Array.from(
        document.getElementsByClassName("text-block")
      );
      for (let idx in delta) {
        tl.fromTo(
          textBoxes[idx],
          0.6,
          {
            x: delta[idx].x,
            y: delta[idx].y,
          },
          {
            x: 0,
            y: 0,
            opacity: 1,
            ease: Power2.easeOut,
          },
          2.5
        );
      }

      tl.to(
        ".container",
        0.5,
        {
          opacity: 0,
        },
        "+=1"
      );
    };

    onMounted(() => {
      mobileScale();
      initializeAnimation();
      window.addEventListener("resize", mobileScale);
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", mobileScale);
      tl.kill();
    });

    return {
      scale,
    };
  },
};
</script>
<style scoped>
.laptop-container {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	-webkit-transform: translate(-50%, -50%);
	-o-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	width: 577px;
	height: 418px;
}
.laptop {
	position: absolute;
	top: 137px;
	left: 119px;
	width: 340px;
	height: 190px;
}
.laptop-bottom path {
  fill: #959c9e;
}
.laptop-bottom rect {
  fill: #ebeff0;
}
.laptop-lid {
  fill: #f5f7f8;
}
.screen-bg {
  fill: #fff;
  opacity: 0;
}
.grid {
  opacity: 0;
  stroke: #ebeff0;
  stroke-width: 2;
}
.chart {
  fill: none;
  stroke: #95c11f;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.chart:nth-child(1) {
  stroke-dasharray: 403;
  stroke-dashoffset: 403;
}
.chart:nth-child(2) {
  stroke-dasharray: 395;
  stroke-dashoffset: 395;
}
.chart:nth-child(3) {
  stroke-dasharray: 332;
  stroke-dashoffset: 332;
}
.chart:nth-child(4) {
  stroke-dasharray: 425;
  stroke-dashoffset: 425;
}
.logo {
  opacity: 0;
}
.logo path:nth-child(1) {
  fill: #95c11f;
}
.logo path:nth-child(2) {
  fill: #fff;
}
.logo text {
  fill: #fff;
  font-size: 7px;
}

.text-block {
  opacity: 0;
  position: absolute;
  font-weight: 600;
}
.text-block.big {
  font-size: 16px;
  padding: 6px 16px;
}
.text-block svg {
  width: 32px;
  float: left;
}
.text-block div {
  float: left;
  line-height: 32px;
  padding-left: 5px;
}
.ai {
  fill: #c64c4c;
  color: #953939;
  top: 49px;
  left: 74px;
  border-radius: 4px;
  background-color: #fcbfbf;
  box-shadow: 0 5px 22px -5px rgba(0, 0, 0, 0.3);
}
.cryptocurrency {
  fill: #cc7825;
  color: #995a1c;
  top: 100px;
  left: 342px;
  border-radius: 4px;
  background-color: #ffd5ab;
  box-shadow: 0 5px 22px -5px rgba(0, 0, 0, 0.3);
}
.autonomous-driving {
  fill: #69b397;
  color: #4f8671;
  top: 360px;
  left: 316px;
  border-radius: 4px;
  background-color: #cdf3e5;
  box-shadow: 0 5px 22px -5px rgba(0, 0, 0, 0.3);
}
.commodities {
  fill: #6d9ebc;
  color: #52778d;
  top: 222px;
  left: 10px;
  border-radius: 4px;
  background-color: #cfe8f7;
  box-shadow: 0 5px 22px -5px rgba(0, 0, 0, 0.3);
}
.white-block {
  color: #636d70;
  border: 1px solid #f5f7f8;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 9px 37px -12px rgba(0, 0, 0, 0.3);
}
.white-block.medium {
  font-size: 14px;
  line-height: 19px;
  padding: 10px 19px;
}
.white-block.small {
  font-size: 12px;
  line-height: 17px;
  padding: 8px 16px;
}
.day-trading {
  top: 0;
  left: 273px;
}
.robotics {
  top: 38px;
  left: 456px;
}
.emerging-markets {
  top: 199px;
  left: 415px;
}
.e-mobility {
  top: 262px;
  left: 441px;
}
.small-caps {
  top: 360px;
  left: 127px;
}
.solar {
  top: 310px;
  left: 27px;
}
.german-stocks {
  top: 131px;
  left: 30px;
}
</style>
