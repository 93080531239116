<template>
  <div class="temoignage-main" ref="temoignageMain">
    <h1>Témoignages</h1>
    <ul class="temoignage-list-main">
      <li
        v-for="(quote, index) in quotes"
        :key="index"
        :class="getClass(index)"
        class="temoignage-list"
      >
        <div class="temoignage-img">
          <img
            class="lsi-review-img"
            alt="London Street Institute Logo"
            :src= "quote.image"
          />
        </div>
        <blockquote class="temoignage-blockquote">
          <p class="temoignage-paragraph">
            {{ quote.content }}
          </p>
          <cite class="temoignage-student">{{ quote.author }}</cite>
        </blockquote>
      </li>
    </ul>
    <br />
  </div>
</template>

<script>
export default {
  data() {
    return {
      quotes: [
        {
          content:"Merci à ma formatrice Jane pour sa patience et son professionnalisme, qui à réussi à me décoincer a l'oral.",
          author: "Monique V.",
          image: require("../assets/review-monique.jpg"),
        },
        {
          content: "Je remercie toute l'équipe de London Street Institute, très bon suivi, plateforme e-learning de grande qualité !",
          author: "Alice M.",
          image: require("../assets/review-alice.png"),
        },
        {
          content: "Très bon entraînement pour passer mon TOEIC, exercices et supports sont un vrai plus, merci.",
          author: "Magali Z.",
          image: require("../assets/review-magali.jpg"),
        },
        {
          content: "Je suis partie de zéro avec une grande appréhension à l'oral, merci à Jane de m'avoir donné envie de continuer et de prendre plaisir à parler !",
          author: "Patricia R.",
          image: require("../assets/review-patricia.jpg"),
        },
      ],
      observer: null,
      animatedElements: [],
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.2, // Adjust this value to control when the intersection is triggered
    });

    const listItems = this.$refs.temoignageMain.querySelectorAll("li");
    listItems.forEach((item) => {
      this.observer.observe(item);
      this.animatedElements.push(false);
    });
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    getClass(index) {
      return {
        "animate-right": index % 2 === 0 && this.animatedElements[index],
        "animate-left": index % 2 === 1 && this.animatedElements[index],
      };
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        const { target, isIntersecting } = entry;
        if (isIntersecting) {
          const index = Array.from(
            this.$refs.temoignageMain.querySelectorAll("li")
          ).indexOf(target);
          if (index >= 0 && !this.animatedElements[index]) {
            this.animatedElements[index] = true;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
/* Your existing styles */
</style>

<style scoped>
.lsi-review-img {
  width: 90px;
}
.temoignage-list-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3em 0 1.5em;
}
.temoignage-list {
  max-width: 470px;
  margin: 2em 1em;
  font-size: 0.9em;
  opacity: 1;
}

.animate-right {
  animation: animate-right 2s ease 0s 1 normal both;
}

.animate-left {
  animation: animate-left 2s ease 0s 1 normal both;
}

@keyframes animate-right {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes animate-left {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.temoignage-img {
  border: 4px solid #fffdd8;
  margin: 1em auto;
  width: 5.5em;
  height: 5.5em;
  border-radius: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.temoignage-blockquote {
  display: flex;
  flex-direction: column;
}

blockquote {
  quotes: none;
}

.temoignage-paragraph {
  font-style: italic;
  letter-spacing: 0.09em;
  line-height: 1.4em;
  padding: 0 0.5em;
  position: relative;
  margin: 0 auto;
  width: 85%;
  text-align: left;
}

.temoignage-paragraph:before {
  content: "“ ";
  display: block;
  position: absolute;
  top: 0;
  left: -30px;
  color: #fffdd8;
  font-size: 4em;
  font-weight: 600;
  font-style: normal;
}

.temoignage-paragraph:after {
  content: "“ ";
  display: block;
  position: absolute;
  bottom: -0.4em;
  right: -30px;
  color: #fffdd8;
  font-size: 4em;
  font-weight: 600;
  font-style: normal;
}

.temoignage-student {
  font-size: 1.2em;
  margin-top: 1.2em;
  text-align: center;
}
</style>
