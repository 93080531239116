<template>
  <Navbar />
  <Laptop />
<div class="formations">
  </div>
    <CookiesMessage />

</template>

<script>
import Navbar from "./Navbar.vue";
import CookiesMessage from "./CookiesMessage.vue";
import Laptop from './Laptop.vue';

export default {
  data() {
    return {
      isMoving: false,
    };
  },
  methods: {
    startMovement() {
      this.isMoving = !this.isMoving;
    },
  },
  components: {
    Navbar,
    CookiesMessage,
    Laptop,
  },
};
</script>

<style>
.formaitons {
  margin: 0 auto;
  position: relative;
  height: calc(100vh - 58px);
  padding-top: 58px;
}


</style>
