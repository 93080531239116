<template>
  <div :style="{ backgroundColor: backgroundColor }">
    <Navbar />

    <div class="section" id="presentation">
      <Presentation />
      <!-- <div class="slide">
        Informations sur les formations que nous proposons:
      </div>
      <div class="slide">
        <h3>Formation sur mesure</h3>
      </div>
      <div class="slide">
        <h3>CPF</h3>
      </div> -->
    </div>
    <div class="section" id="engagements">
      <Engagements v-if="!isMobile" />
      <EngagementsMobile v-else />
    </div>
    <div class="section" id="contact-form">
      <ContactForm />
    </div>
    <div class="section-test" id="temoiggagnes">
      <Temoignages />
    </div>
    <!-- <div class="section" id="e-learning">
      <h3>E-Learning</h3>
    </div> -->
    <Footer />
    <CookiesMessage />
  </div>
</template>

<script>
import Presentation from "./Presentation.vue";
import Navbar from "./Navbar.vue";
import ContactForm from "./ContactForm.vue";
import Engagements from "./Engagements.vue";
import EngagementsMobile from "./EngagementsMobile.vue";
import Footer from "./Footer.vue";
import CookiesMessage from "./CookiesMessage.vue";
import Temoignages from "./Temoignages.vue";

export default {
  components: {
    Presentation,
    Navbar,
    ContactForm,
    Engagements,
    EngagementsMobile,
    Footer,
    CookiesMessage,
    Temoignages,
  },
  data() {
    return {
      backgroundColor: "#28A3E0", // Initial background color
      colorStops: [
        { position: 0, color: [40, 163, 224] }, // Start color (RGB) at position 0
        { position: 0.2, color: [185, 58, 214] }, 
        { position: 0.4, color: [235, 63, 129] }, 
        { position: 0.6, color: [227, 131, 48] }, 
        { position: 0.8, color: [236, 191, 39] }, 
        { position: 1, color: [58, 189, 128] }, // End color (RGB) at position 1
      ],
      isMobile: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 1140;
    },
    handleScroll() {
      const scrollPosition =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const maxScroll = document.documentElement.scrollHeight - windowHeight;
      const scrollPercentage = scrollPosition / maxScroll;

      // Find the color stops surrounding the current scroll percentage
      let startIndex = 0;
      let endIndex = this.colorStops.length - 1;
      for (let i = 0; i < this.colorStops.length; i++) {
        if (scrollPercentage <= this.colorStops[i].position) {
          endIndex = i;
          break;
        }
        startIndex = i;
      }

      // Calculate the position within the two color stops
      const startColorStop = this.colorStops[startIndex];
      const endColorStop = this.colorStops[endIndex];
      const positionInRange =
        (scrollPercentage - startColorStop.position) /
        (endColorStop.position - startColorStop.position);

      // Interpolate the colors based on the position
      const currentColor = startColorStop.color.map((start, index) => {
        const end = endColorStop.color[index];
        const color = Math.round(start + (end - start) * positionInRange);
        return color;
      });

      // Set the new background color
      this.backgroundColor = `rgb(${currentColor.join(",")})`;
    },
  },
};
</script>

<style scoped>
.section {
  height: calc(100vh - 58px);
  padding-top: 80px;
}
</style>
