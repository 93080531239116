<template>
    <transition name="fade">
      <div class="cookie-message" v-show="showMessage">
        <p>{{ message }}</p>
        <p>
          <a class="plus-infos" href="https://londonstreetinstitute.fr/mentions-legales/">Plus d'infos</a>
        </p>
        <button class="ok-accept" @click="accept">OK, j'ai bien compris!</button>
      </div>
    </transition>
  </template>
  
  <script>
  import VueCookies from "vue-cookies";
  
  export default {
    data() {
      return {
        accepted: false,
        message:
          "Nous utilisons des cookies pour améliorer ton expérience sur notre site. En continuant ta visite, tu acceptes notre utilisation des cookies.",
      };
    },
    created() {
      this.accepted = VueCookies.get("cookieAccepted") === "true";
    },
    computed: {
      showMessage() {
        return !this.accepted;
      },
    },
    methods: {
      accept() {
        console.log("cookies accepted");
        this.accepted = true;
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 365);
        VueCookies.set("cookieAccepted", true, expirationDate);
      },
    },
  };
  </script>
  
  <style scoped>
  .plus-infos {
    font-size: 12px;
    letter-spacing: 1px;
  }
  
  .cookie-message {
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 2em;
    text-align: center;
    max-width: 24em;
    background-color: #232323;
    text-align: left;
    font-family: "FuturaStdHeavy", sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #fffdd8;
  }
  
  p {
    letter-spacing: 1px;
  }  
  .ok-accept {
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0.6em 0;
    font-size: 0.9em;
    font-weight: 700;
    border-width: 2px;
    border-style: solid;
    text-align: center;
    white-space: nowrap;
    color: #fffdd8;
    border-color: transparent;
    background-color: #dc661b;
    margin-top: 20px;
    width: 100%;
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  </style>
  