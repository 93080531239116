<template>
  <div>
    <img
      class="lsi-logo-main"
      alt="London Street Institute Logo"
      src="../assets/lsi-logo.png"
    />
    <h1>Qui sommes-nous ?</h1>
    <br />
    <p>
      Un super centre de formation en langues pour les pros, les entreprises et
      même toi !
    </p>
    <p>
      Nos profs d'anglais sont super qualifiés et ont plein d'expérience pro, en
      plus ils parlent leur langue maternelle. Ce qui est cool, c'est qu'on est
      toujours à la pointe de l'innovation dans l'apprentissage et la
      technologie.
    </p>
    <p>
      On crée des formations sur mesure qui collent à la réalité du marché pro,
      donc tu apprends exactement ce dont tu as besoin. Et en prime, toutes nos
      formations sont certifiantes et éligibles au CPF. On est même partenaire
      officiel d'ETS GLOBAL pour les fameux tests TOEIC.
    </p>
    <p>
      Que tu préfères les cours à distance ou en présentiel pour une immersion
      totale, on s'adapte à ton profil et on te concocte un programme
      personnalisé pour atteindre tes objectifs.
    </p>
    <!-- <p>
      Et notre site internet, il est pas génial ? Tu as ton propre espace perso,
      avec des tests d'évaluation tout au long de ta formation, des ressources
      en ligne, des supports de cours et même des rapports sur ta progression
      jusqu'au fameux test TOEIC.
    </p> -->
    <p>
      Viens nous rejoindre, on est vraiment sympas et on va te faire adorer
      l'anglais !
    </p>
    <ul class="pedagogie">
      <li id="">
        <strong class="pedagogie-title">Des cours modulables</strong>
        <span class="pedagogie-sn">en présentiels ou en distanciels !</span>
      </li>
      <li id="">
        <strong class="pedagogie-title">Des formateurs natifs</strong>
        <span class="pedagogie-sn"
          >le top pour apprendre une langue et sa culture !</span
        >
      </li>
      <li id="">
        <strong class="pedagogie-title">Tout sur mesure</strong>
        <span class="pedagogie-sn"
          >Pour tout niveau et tout age, en petits groupes ou individuel</span
        >
      </li>
      <li id="">
        <strong class="pedagogie-title">100% financé</strong>
        <span class="pedagogie-sn">Grace au CPF, tout peut arriver !</span>
      </li>
    </ul>
  </div>
</template>

<script></script>
<style scoped>
.pedagogie {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.pedagogie-title {
  display: block;
  font-weight: 900;
  text-transform: uppercase;
}
ul {
  display: flex;
  text-align: center;
  justify-content: center;
  margin: 2em 0;
}
li {
  /*font-size: 0.52em;*/
  line-height: 1.4;
  margin: 1em 1.5em;
  width: 11em;
}
li:before {
  background-size: 70%;
  content: "";
  display: block;
  width: 3.5em;
  height: 3.5em;
  margin: 0 auto 0.75em;
  border-radius: 100%;
  background-image: url(../assets/heart.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-color: rgba(255, 253, 216, 0.12);
}
span {
  font-weight: 600;
  padding: 0 1em;
  display: inline-block;
}

p {
  letter-spacing: 0;
  font-size: 1em;
  font-family: "Poppins", sans-serif;
}
.lsi-logo-main {
  width: 500px;
}

@media screen and (max-width: 900px) {
  .lsi-logo-main {
    width: 400px;
    margin-top: 2em;
  }
}

@media screen and (max-width: 520px) {
  .lsi-logo-main {
    width: 350px;
    margin-top: 5em;
  }
}

p {
  margin: auto;
  padding: 0.2em;
  max-width: 57.5em;
  letter-spacing: 3px;
}
</style>
