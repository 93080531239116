<template>
  <div class="container">
    <h1>Nos Engagements</h1>
    <div ref="engagements" class="section" @scroll="handleScroll">
      <div :class="['step-wrapper', { 'move-line': isMoving }]">
        <article class="linestep linestep1" style="left: 48%; top: -2%">
          <span class="num">1</span>
          <p class="bottom">
            Évaluation en ligne du niveau écrit et oral selon le CECRL
          </p>
        </article>
        <article class="linestep linestep2" style="left: 78%; top: -2%">
          <span class="num">2</span>
          <p class="bottom">Acces à la plateforme E-learning 24h/24 et 7j/7</p>
        </article>
        <article class="linestep linestep3" style="right: -0.5%; top: 20%">
          <span class="num">3</span>
          <p class="left">
            L'apprenant recevra sa note et sera dirigé vers la formation la plus
            adéquate pour lui
          </p>
        </article>
        <article class="linestep linestep4" style="left: 68%; top: 44%">
          <span class="num"> 4</span>
          <p class="bottom">
            Détermination des objectifs linguistiques en lien avec le formateur
            et coordinateur
          </p>
        </article>

        <article class="linestep linestep5" style="left: 32%; top: 44.5%">
          <span class="num">5</span>
          <p class="top">
            Préparation des cours et jeux de rôles suivant la méthode London
            Street Insitute
          </p>
        </article>

        <article class="linestep linestep6" style="left: -1.5%; top: 68%">
          <span class="num"> 6</span>
          <p class="right">
            Un formateur-coach individuel ientique pendant toute la formation
          </p>
        </article>

        <article class="linestep linestep7" style="left: 21%; top: 93%">
          <span class="num"> 7</span>
          <p class="bottom">
            Test de milieu de session et évaluation de fin de forfait avec
            possibilité de passer son TOEIC
          </p>
        </article>

        <article class="linestep linestep8" style="left: 45%; top: 93%">
          <span class="num"> 8</span>
          <p class="top">
            Formateurs anglais/américains natifs (au choix) polyvalents,
            spécialisés ou experts dans diverses fonctions et métiers
          </p>
        </article>

        <article class="linestep linestep9" style="left: 69%; top: 93%">
          <span class="num"> 9</span>
          <p class="bottom">
            Suivi post-cours par le formateur pour l'apprenant, incluant les
            points clés abordés (expressions, vocabulaire, applications
            grammaticales, etc.)
          </p>
        </article>


        <article class="linestep linestep10" style="left: 92%; top: 93%">
          <span class="num"> 10</span>
          <p class="top">
            Grille de progression personnalisée, temps passé en formation
            automatisé
          </p>
        </article>
        <!-- Rest of the articles -->
        <svg
          width="100%"
          viewBox="0 0 1156 608"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            class="path"
            d="m560.30957,10.588011c0,0 438.0947,1.90476 439.04708,1.90476c0.95238,0 144.57857,-1.02912 143.80934,137.14269c-0.76923,138.17181 -116.81095,142.30859 -131.61967,143.8923c-14.80873,1.58372 -840.41472,-0.71429 -860.5941,0.71429c-20.17938,1.42858 -148.4991,6.80903 -146.83244,147.05973c1.66666,140.2507 129.52365,152.14266 129.33243,151.27321c0.19122,0.86945 815.268425,2.687632 951.42748,0"
            opacity="0.5"
            stroke-width="7"
            stroke="#fffdd8"
            fill="none"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMoving: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const engagementsSection = this.$refs.engagements;
      const sectionTop = engagementsSection.getBoundingClientRect().top;
      const windowHeight = window.innerHeight;

      if (sectionTop < windowHeight) {
        this.startMovement();
      }
    },
    startMovement() {
      this.isMoving = true;
    },
  },
};
</script>

<style lang="scss">
.container {
  max-width: 1260px;
  margin: 0 auto;
  position: relative;
}

.step-wrapper {
  margin: 30px;
  margin-bottom: 200px;
  position: relative;
}

.linestep {
  text-align: center;
  max-width: 250px;
  position: absolute;
  .num {
    color: #222;
    border-radius: 100px;
    display: inline-block;
    width: 40px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    background: #fffdd8;
  }
  p {
    width: 250px;
    position: absolute;
    margin-top: 7px;
    line-height: normal;
    font-size: 14px;
  }

  p.top {
    bottom: 50px;
    left: -110px;
  }
  p.bottom {
    left: -110px;
  }
  p.left {
    width: 300px;
    right: 34px;
    top: -17px;
  }
  p.right {
    width: 300px;
    left: 34px;
    top: -17px;
  }
}

.linestep {
  visibility: hidden;
}
.path {
  visibility: hidden;
}

.move-line {
  .path {
    visibility: visible;
    stroke-dasharray: 3700;
    stroke-dashoffset: 3700;
    animation: moveline 4s linear forwards;
  }
  .linestep {
    animation-name: show-step-item;
    animation-duration: 0.2s;
    animation-fill-mode: forwards;
  }
  .linestep1 {
    animation-delay: 0.4s;
  }
  .linestep2 {
    animation-delay: 0.8s;
  }
  .linestep3 {
    animation-delay: 1.2s;
  }
  .linestep4 {
    animation-delay: 1.6s;
  }
  .linestep5 {
    animation-delay: 2s;
  }
  .linestep6 {
    animation-delay: 2.4s;
  }
  .linestep7 {
    animation-delay: 2.8s;
  }
  .linestep8 {
    animation-delay: 3.2s;
  }
  .linestep9 {
    animation-delay: 3.6s;
  }
  .linestep10 {
    animation-delay: 4s;
  }
}

@keyframes show-step-item {
  from {
    transform: scale(0.2);
    opacity: 0;
  }
  to {
    visibility: visible;
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes moveline {
  to {
    stroke-dashoffset: 0;
  }
}
</style>
