<template>
  <footer class="footer-distributed">
    <div class="footer-left">
      <!-- <h3>Company<span>logo</span></h3> -->
      <img
        class="lsi-logo"
        alt="London Street Institute Logo"
        src="../assets/lsi-logo.png"
      />

      <p class="footer-links">
        <a
          href="https://londonstreetinstitute.fr/wp-content/uploads/2022/02/Conditions-generales-de-vente.pdf"
          class="link-1"
          >Conditions Générales</a
        >

        <a href="https://londonstreetinstitute.fr/mentions-legales/"
          >Mentions légales</a
        >
      </p>

      <p class="footer-company-name">
        London Street Institute by CLK LEARNING © 2021 - Siret : 893 871 111
        00017
      </p>
    </div>

    <div class="footer-center">
      <div>
        <i class="fa fa-map-marker"></i>
        <p><span>14 Av. de l'Opéra,<br>75001 Paris, France </span></p>
      </div>

      <div>
        <i class="fa fa-phone"></i>
        <p>+33 1 42 60 10 50</p>
      </div>
      <div>
        <i class="fa fa-envelope"></i>
        <p>
          <a href="mailto:support@company.com"
            >contact@londonstreetinstitute.fr</a
          >
        </p>
      </div>
    </div>

    <div class="footer-right">
      <p class="footer-company-about">
        <span>Où nous trouver:</span>
      </p>

      <div class="map-container">
        <div class="embedded-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2624.629267980103!2d2.3322978127294585!3d48.86527947121358!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e254268e9b1%3A0x691940ded17fcfcb!2s14%20Av.%20de%20l&#39;Op%C3%A9ra%2C%2075001%20Paris%2C%20France!5e0!3m2!1sen!2suk!4v1687854170447!5m2!1sen!2suk"
            width="400"
            height="300"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
      <!-- 
      <div class="footer-icons">
        <a href="#"><i class="fa fa-facebook"></i></a>
        <a href="#"><i class="fa fa-twitter"></i></a>
        <a href="#"><i class="fa fa-linkedin"></i></a>
        <a href="#"><i class="fa fa-github"></i></a>
      </div> -->
    </div>
  </footer>
</template>
<script setup></script>
<style scoped>
.lsi-logo {
  width: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: block;
  padding-bottom: 20px;
}
.map-container {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.embedded-map {
  position: relative;
  width: 100%;
  height: 100%;
}
.footer-distributed {
  background: #222;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  /* font: bold 16px sans-serif; */
  padding: 55px 50px;
}

.footer-distributed .footer-left,
.footer-distributed .footer-center,
.footer-distributed .footer-right {
  display: inline-block;
  vertical-align: top;
}

/* Footer left */

.footer-distributed .footer-left {
  width: 40%;
}

/* The company logo */

/* .footer-distributed h3 {
  color: #fffdd8;
  font: normal 36px "Open Sans", cursive;
  margin: 0;
} */

.footer-distributed h3 span {
  color: lightseagreen;
}

/* Footer links */

.footer-distributed .footer-links {
  color: #fffdd8;
  margin: 20px 0 12px;
  padding: 0;
}

.footer-distributed .footer-links a {
  display: inline-block;
  line-height: 1.8;
  font-weight: 400;
  text-decoration: none;
  color: inherit;
}

.footer-distributed .footer-company-name {
  color: #fffdd8;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

/* Footer Center */

.footer-distributed .footer-center {
  width: 35%;
}

.footer-distributed .footer-center i {
  background-color: #33383b;
  color: #fffdd8;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer-distributed .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer-distributed .footer-center p {
  display: inline-block;
  color: #fffdd8;
  font-weight: 400;
  vertical-align: middle;
  margin: 0;
}

.footer-distributed .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer-distributed .footer-center p a {
  color: lightseagreen;
  text-decoration: none;
}

.footer-distributed .footer-links a:before {
  content: "|";
  font-weight: 300;
  font-size: 20px;
  left: 0;
  color: #fffdd8;
  display: inline-block;
  padding-right: 5px;
}

.footer-distributed .footer-links .link-1:before {
  content: none;
}

/* Footer Right */

.footer-distributed .footer-right {
  width: 20%;
}

.footer-distributed .footer-company-about {
  line-height: 20px;
  color: #92999f;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer-distributed .footer-company-about span {
  display: block;
  color: #fffdd8;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer-distributed .footer-icons {
  margin-top: 25px;
}

.footer-distributed .footer-icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;

  font-size: 20px;
  color: #fffdd8;
  text-align: center;
  line-height: 35px;

  margin-right: 3px;
  margin-bottom: 5px;
}

/* If you don't want the footer to be responsive, remove these media queries */

@media (max-width: 880px) {
  .footer-distributed {
    font: bold 14px sans-serif;
  }

  .footer-distributed .footer-left,
  .footer-distributed .footer-center,
  .footer-distributed .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }

  .footer-distributed .footer-center i {
    margin-left: 0;
  }
}
</style>
```
