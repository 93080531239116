<template>
  <Navbar />
  <main class="main">
    <div>
      <header class="header-block">
        <div class="header-block-content">
          <h1 class="h1-header-block">
            <span>
              Blabla <br />
              Hello
            </span>
          </h1>
        </div>
        <div class="header-block-subconcent">Pédagogie</div>
      </header>
      <div class="pedagogie-block">
        <section class="pedagogie-1">
          <div class="pedagogie-d-1">
            <h2 class="pedagogie-h-1">A distance</h2>
            <span>
              100% a distance!
            </span>
            <span class="sp-pedagogie">
              100% à distance!
              
            </span>
          </div>
          <section>
            <h3 data-count="1"></h3>
            <div>
              <p>
                Depuis l’interface d’apprentissage, l’étudiant entend et voit
                les frimousses des formateurs qui animent le cours. Il dispose
                d’une présentation diaporama et d’une fenêtre de code, afin de
                suivre attentivement les explications. Un fil de discussion est
                également accessible à toute la classe. Une question ?
                Formateurs et étudiants en prennent connaissance et peuvent y
                répondre immédiatement. A tout moment, les formateurs peuvent
                accéder à l’écran de travail des étudiants pour les aider ou les
                débloquer.
              </p>
              <p>
                Depuis l’interface d’apprentissage, l’étudiant entend et voit
                les frimousses des formateurs qui animent le cours. Il dispose
                d’une présentation diaporama et d’une fenêtre de code, afin de
                suivre attentivement les explications. Un fil de discussion est
                également accessible à toute la classe. Une question ?
                Formateurs et étudiants en prennent connaissance et peuvent y
                répondre immédiatement. A tout moment, les formateurs peuvent
                accéder à l’écran de travail des étudiants pour les aider ou les
                débloquer.
              </p>
            </div>
          </section>

          <section>
            <h3 data-count="2">
              <span class="h3-span">On se détend, on apprend</span>
            </h3>
            <div>
              <p>
                Quand on a goûté aux joies de bosser en pantoufles, c’est
                difficile de revenir en arrière. On est à l’aise chez soi, on
                s’y sent bien et forcément, on travaille mieux. C’est aussi ce
                confort qui donne une saveur particulière aux formations
                O’clock.
              </p>
              <p>
                Quand on a goûté aux joies de bosser en pantoufles, c’est
                difficile de revenir en arrière. On est à l’aise chez soi, on
                s’y sent bien et forcément, on travaille mieux. C’est aussi ce
                confort qui donne une saveur particulière aux formations
                O’clock.
              </p>
              <p>
                Quand on a goûté aux joies de bosser en pantoufles, c’est
                difficile de revenir en arrière. On est à l’aise chez soi, on
                s’y sent bien et forcément, on travaille mieux. C’est aussi ce
                confort qui donne une saveur particulière aux formations
                O’clock.
              </p>
            </div>
          </section>

          <section>
            <h3 data-count="3">
              <span>
                Le télétravail, c'est bien, mais on a besoin de se voir
              </span>
            </h3>
            <div>
              <p>
                Quand on a goûté aux joies de bosser en pantoufles, c’est
                difficile de revenir en arrière. On est à l’aise chez soi, on
                s’y sent bien et forcément, on travaille mieux. C’est aussi ce
                confort qui donne une saveur particulière aux formations
                O’clock.
              </p>
              <p>
                Quand on a goûté aux joies de bosser en pantoufles, c’est
                difficile de revenir en arrière. On est à l’aise chez soi, on
                s’y sent bien et forcément, on travaille mieux. C’est aussi ce
                confort qui donne une saveur particulière aux formations
                O’clock.
              </p>
              <p>
                Quand on a goûté aux joies de bosser en pantoufles, c’est
                difficile de revenir en arrière. On est à l’aise chez soi, on
                s’y sent bien et forcément, on travaille mieux. C’est aussi ce
                confort qui donne une saveur particulière aux formations
                O’clock.
              </p>
              <p>
                Quand on a goûté aux joies de bosser en pantoufles, c’est
                difficile de revenir en arrière. On est à l’aise chez soi, on
                s’y sent bien et forcément, on travaille mieux. C’est aussi ce
                confort qui donne une saveur particulière aux formations
                O’clock.
              </p>
            </div>
          </section>
        </section>

        <div id="une-journee-chez-oclock">
          <section></section>
        </div>
      </div>
    </div>
  </main>

  <div>
    <Footer />
    <CookiesMessage />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CookiesMessage from "@/components/CookiesMessage.vue";
import Footer from "@/components/Footer.vue";
export default {
  name: "Pedagogie.vue",

  components: {
    Navbar,
    CookiesMessage,
    Footer,
  },
};
</script>

<style scoped>
.main {
  background-color: pink;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header-block {
  background: rgb(231, 96, 90);
  color: rgb(255, 253, 216);
  height: 100vh;
  padding-top: 25vh;
  font-size: 1.8rem;
  line-height: 1.2;
  text-align: center;
  position: relative;
  text-transform: uppercase;
}
.header-block-content {
}
.h1-header-block {
  font-size: 3.35em;
  font-weight: 900;
  padding: 0px 3em;
  position: relative;
}
.header-block-subconcent {
  background: rgb(225,53,45);
  position: absolute;
  left: 50%;
  top: 60%;
  font-weight: 700;
  letter-spacing: 0.12em;
  height: 3.5em;
  line-height: 3.5em;
  margin-top: -1.75em;
  width: 14em;
  margin-left: -7em;
  z-index: 2;
}
.pedagogie-block {
  background: rgb(255, 255, 255);
  display: block;
  margin: -40vh 6em 0px;
  position: relative;
  z-index: 1;
}
.pedagogie-1 {
  padding-left: 6em;
  padding-right: 6em;
  padding-top: 7.5em;
}
.pedagogie-d-1 {
  font-size: 2.4rem;
  text-align: center;
  color: rgb(50,50,50);

}
.pedagogie-h-1 {
  font-weight: 900;
  letter-spacing: 0.12em;
  line-height: 1.2;
  text-transform: uppercase;
  color: rgb(50,50,50);
}
.sp-pedagogie {
  color:rgb(231, 96, 90);
  display: block;
  font-size: 3.2em;
  letter-spacing: 0.14em;
}
</style>
