<template>
  <Main />
<!-- <img alt="Vue logo" src="./assets/logo.png"> -->
<!-- <HelloWorld msg="Welcome to London Street Institute v2"/> -->
<PostCard />

<CookiesMessage />
<router-view />
<Footer />
</template>

<script>


export default {
name: 'App',
}
import './assets/app.css';
</script>

<style>
#app {
font-family: 'FuturaStdHeavy', sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
text-align: center;
/*color: #2c3e50;*/
/*margin-top: 60px;*/
}
</style>
