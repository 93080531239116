<template>
  <nav class="navbar-container">
    <div class="navbar">
      <ul class="navbar-ul">
        <li class="navbar-li-left">
          <a href="/">Accueil</a>
        </li>
        <li class="navbar-li-left">
          <router-link to="/pedagogie">Pédagogie</router-link>
        </li>
        <li class="navbar-li-left">
          <router-link to="/formations">Formations</router-link>
        </li>

        <li class="navbar-li-middle">
          <a href="/"
            ><img
              class="lsi-logo"
              :class="{ 'hide-logo': !showLogo }"
              alt="London Street Institute Logo"
              src="../assets/lsi-logo.png"
          /></a>
        </li>
        <li class="navbar-li-middle">
          <a href="#e-learning">Espace E-learning</a>
        </li>

        <li class="navbar-li-right">
          <a id="navsignup" class="navsignup" href="/devenir-etudiant"
            >Je m'inscris</a
          >
        </li>
      </ul>
    </div>
  </nav>
</template>
<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';

export default {
  setup() {
    const showLogo = ref(false);

    const handleScroll = () => {
      if (window.scrollY < 300) {
        showLogo.value = false;
      } else {
        showLogo.value = true;
      }
    };

    onMounted(() => {
      window.addEventListener('scroll', handleScroll);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', handleScroll);
    });

    return {
      showLogo,
    };
  },
};
</script>
<style>
.hide-logo {
  display: none;
}

.lsi-logo {
  width: 100%;
  height: auto;
  max-width: 120px;
  margin: 0 auto;
}
.navbar-container {
  background: inherit;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-size: 1.5rem;
  height: 58px;
  z-index: 10;

  /* 
  color: #fffdd8;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 0.9em;
    font-weight: 900;
    height: 58px;
    -webkit-letter-spacing: 0.15em;
    -moz-letter-spacing: 0.15em;
    -ms-letter-spacing: 0.15em;
    letter-spacing: 0.15em;
    padding: 0 1em;
    text-transform: uppercase;
    position: relative;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-transition: 0.2s background;
    transition: 0.2s background; */
}
.navbar {
  position: relative;
  z-index: 12;
}
#navsignup {
padding-bottom: 18px;
}
li a.active::before {
  left: 1rem;
  content: "";
  background: #fffdd8;
  height: 0.15em;
  position: absolute;
  left: 2em;
  right: 2em;
  bottom: 0;
  opacity: 0;
  transition: 0.4s;
  opacity: 1;
}
li a {
  color: #fffdd8;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  font-weight: 900;
  height: 58px;
  -webkit-letter-spacing: 0.15em;
  -moz-letter-spacing: 0.15em;
  -ms-letter-spacing: 0.15em;
  letter-spacing: 0.15em;
  padding: 0 1em;
  text-transform: uppercase;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.2s background;
  transition: 0.2s background;
}
li a:hover {
  background: rgba(21, 16, 0, 0.07);
}
.navbar::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(21, 16, 0, 0.12);
  z-index: -9;
}
.navbar-ul {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin: auto;
  overflow: hidden;
  padding: 0 1em;
}

.navbar-li-left {
  height: 58px;
  margin: 0 0.2em;
}
@media (min-width: 1200px) {
  .navbar-li-middle {
    margin-left: auto;
  }
}
.navbar-li-right {
  color: #fffdd8;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  /* display: flex; */
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.9em;
  font-weight: 900;
  height: 58px;
  -webkit-letter-spacing: 0.15em;
  -moz-letter-spacing: 0.15em;
  -ms-letter-spacing: 0.15em;
  letter-spacing: 0.15em;
  padding: 0 1em;
  text-transform: uppercase;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: 0.2s background;
  transition: 0.2s background;
  border: 2px solid #fffdd8;
  border-radius: 2em;
  height: 42px;
  margin: auto 0;
}
</style>
